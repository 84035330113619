.header {
  -webkit-text-stroke: 0.5px black;
  background-color: #2699fb;
  border-radius: 10px;
  font-size: 28px;
  font-family: HelveticaNeue-Bold, Helvetica Neue;
  font-weight: 700;
  color: #fff;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.navbar {
  display: flex;
}

.dropdown {
  display: flex;
  justify-content: space-evenly;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.btn {
  border: double;
  padding: 10px;
  margin: 10px;
  text-align: center;
  border-radius: 6px;
}

.dropdown1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#matches-dropdown1 {
}

#matches-content1 {
  /* visibility: hidden; */
  display: none;
}

#matches-dropdown1:hover #matches-content1 {
  /* visibility: visible; */
  display: block;
}

#matches-dropdown2 {
}

#matches-content2 {
  /* visibility: hidden; */
  display: none;
}

#matches-dropdown2:hover #matches-content2 {
  /* visibility: visible; */
  display: block;
}

@media (max-width: 800px) {
  .navbar {
    display: flex;
    margin-right: 0.3em;
    /* resize image */
    /* background-image: url('./../images/nicaraugua/img_9251_dog_on_the_steps.jpg'); */
  }

  .content {
    display: flex;
  }
}

@media (max-width: 570px) {
  .content {
    display: flex;
    flex-shrink: 1;
    justify-content: center;
  }
}

@media (max-width: 530px) {
  .dropdown {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .auth {
  }
}

/* .btn media query isn't working as expected */
@media (max-width: 465px) {
  .dropdown1 {
    display: flex;
    direction: column;
  }
  .btn {
    margin: 0px;
  }
}
