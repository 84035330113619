a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* .header {
  -webkit-text-stroke: 0.5px black;
  background-color: #2699fb;
  border-radius: 10px;
  font-size: 28px;
  font-family: HelveticaNeue-Bold, Helvetica Neue;
  font-weight: 700;
  color: #fff;
} */

.App-link {
  color: #61dafb;
}

.match-link {
  text-decoration: none;
  padding: 0.5em 0.5em;
  color: rgba(155, 4, 4, 0.75);
}

.players,
.matches {
}

.player,
.match {
  border: 4px dotted;
}

.currentlyPlaying {
  border: 4px dotted #1d741b;
}

.playing {
  color: #1d741b;
}

.score-board {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
}

.scores {
  display: flex;
  justify-content: center;
}

.score,
.stat {
  /* padding: 0.5em 0.5em; */
  border-radius: 20px;
  font-size: 20px;
  font-family: Arial-BoldMT, Arial;
  font-weight: 700;
  background-color: #0e05fa;
  color: #000;
  text-shadow: 2px 2px #ffffffbb;
}
.score-stat {
  width: 160px;
  height: 60px;
}
.home-team {
  background-color: #0e05fa;
}
.away-team {
  background-color: #fafa05;
}
.pitch-stat {
  width: 110px;
  height: 60px;
  color: #000;
}
.pitch-count {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.pitch-count-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.balls {
  background-color: #05f76b;
}
.strikes {
  background-color: #05f76b;
}
.fouls {
  background-color: #05f76b;
}
.outs {
  background-color: red;
}
.my-current-match {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.kicker-card {
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 125px;
  background-color: rgba(44, 43, 43, 0.5);
  border-radius: 20px;
}
.kicker {
  max-width: 115px;
  border-radius: 20px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
